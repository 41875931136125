<template>
  <v-dialog
    v-model="internalDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-form
        ref="form"
        method="post"
        action="/"
        lazy-validation
        @submit.prevent="handleImport"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-if="showImportTypeSelection"
                cols="12"
              >
                <v-select
                  v-model="importType"
                  :items="importTypes"
                  label="Import From"
                  outlined
                ></v-select>
              </v-col>

              <!-- Router Selection - shown when importing from router -->
              <v-col
                v-if="importType === 'router'"
                cols="12"
              >
                <v-select
                  v-model="form.router_id"
                  :items="displayRouters"
                  item-text="displayText"
                  item-value="id"
                  label="Select a router"
                  :rules="[v => !!v || 'Router is required']"
                  :loading="isRoutersLoading"
                  :error="form.errors.has('router_id')"
                  :error-messages="form.errors.get('router_id')"
                  outlined
                ></v-select>
                <span v-if="!isRoutersLoading && routers.length === 0">
                  There are no Routers available. Please go to <router-link to="/routers">Routers</router-link> page and create one.
                </span>
              </v-col>

              <!-- File Import UI - shown when importing from file -->
              <v-col
                v-if="importType === 'file'"
                cols="12"
              >
                <p>To ensure a smooth import process, please download and use the provided template. The template outlines the correct format.</p>
                <v-btn
                  class="mb-4"
                  color="primary"
                  small
                  :disabled="isDownloadingTemplate"
                  :loading="isDownloadingTemplate"
                  @click="downloadTemplate"
                >
                  Download Template
                </v-btn>

                <div>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-title>
                        <strong>Required fields:</strong>
                        <div>username, name, account_number, phone_number,</div>
                        <div>internet_connection_type, internet_plan, expiry_date, station.</div>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>Optional fields:</strong>
                          <div>created_at, email, physical_address, debt, credits, password.</div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <v-file-input
                  v-model="form.file"
                  show-size
                  label="Click to select a file"
                  class="mt-4"
                  outlined
                  dense
                  accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ></v-file-input>

                <div
                  v-if="Object.keys(formErrors).length > 0"
                  class="error-messages"
                >
                  <ul>
                    <li
                      v-for="(errors, field) in formErrors"
                      :key="field"
                    >
                      <strong>{{ field }}:</strong>
                      <span class="red--text">{{ errors.join(', ') }}</span>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :loading="form.busy"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    showImportTypeSelection: {
      type: Boolean,
      default: true,
    },
    defaultImportType: {
      type: String,
      default: 'router',
      validator: value => ['router', 'file'].includes(value),
    },
  },

  data: () => ({
    isRoutersLoading: false,
    isDownloadingTemplate: false,
    importType: 'router',
    importTypes: [
      { text: 'Import from Router', value: 'router' },
      { text: 'Import from File', value: 'file' },
    ],
    form: new Form({
      router_id: '',
      file: null,
    }),
    routers: [],
    formErrors: {},
  }),

  computed: {
    internalDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    displayRouters() {
      return this.routers.map(router => ({
        ...router,
        displayText: `${router.station.name} Router`,
      }))
    },
  },

  watch: {
    value(newVal) {
      if (newVal) {
        this.fetchRouters()
      }
    },
    defaultImportType: {
      immediate: true,
      handler(value) {
        this.importType = value
      },
    },
  },

  methods: {
    downloadTemplate() {
      this.isDownloadingTemplate = true
      axios({
        method: 'GET',
        url: 'download-import-customers-template',
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)

        const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition']
        let filename = 'CustomersData.xlsx'

        if (contentDisposition) {
          const filenameRegex = /filename="?([^"]+)"?\b/
          const matches = contentDisposition.match(filenameRegex)
          if (matches && matches[1]) {
            filename = matches[1]
          }
        }

        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(error => {
        this.$toast.error('There was an error downloading the template')
      }).finally(() => {
        this.isDownloadingTemplate = false
      })
    },

    handleImport() {
      if (this.importType === 'router') {
        this.importFromRouter()
      } else {
        this.importFromFile()
      }
    },

    importFromRouter() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post(`${this.url}/${this.form.router_id}`)
          .then(response => {
            this.$toast.success('Imported successfully')
            this.$emit('imported', response.data)
            this.close()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },

    importFromFile() {
      this.form.busy = true
      this.formErrors = {}
      const formData = new FormData()
      formData.append('file', this.form.file)

      axios.post('import-customers', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        this.$emit('imported')
        this.$toast.success('Imported successfully')
        this.close()
      }).catch(error => {
        if (error.response && error.response.data.errors) {
          this.formErrors = error.response.data.errors
        } else {
          console.error('Import error:', error)
          this.$emit('import-failed', error)
          const errorMessage = error.response.data.error || error.response.data.message
          if (errorMessage) {
            this.$toast.error(`Import failed: ${errorMessage}`)
          }
        }
      }).finally(() => {
        this.form.busy = false
      })
    },

    fetchRouters() {
      this.isRoutersLoading = true
      axios.get('/routers?perPage=all')
        .then(response => {
          this.routers = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isRoutersLoading = false
        })
    },

    close() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form.errors.clear()
      this.$refs.form.reset()
      this.formErrors = {}
    },
  },
}
</script>
